*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
.cardHighlight {
    min-height: 100%;
}

html, body {
    height: 100%
}

#root {
  height: 100%;
}

.content-container {
    position: relative;
    top: 48px;
    z-index: 0;
}

.navbar {
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }